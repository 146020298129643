<template>
  <div class="whole">
    <Nav></Nav>
    <div class="all desktop-present">
      <div class="activity-top" v-if="isShowImg">
        <img :src="iconImgURL" alt="" class="yan-img" />
      </div>
      <div class="activity-center">
        <div class="btn">
          <div class="btn-con" v-if="activityInfo.results">
            <div @click="onClikActivity(k.id)" v-for="k in showList" :class="{ active: currentRed == k.id }" :key="k.id">
              <div>{{ k.name }}</div>
            </div>
            <div v-if="activityInfo.results.length != 0" :class="{ active: currentRed == 5 }" class="btn-re" @click="onClikActivity(5)">获奖作品</div>
          </div>
        </div>
        <div class="activity-con">
          <div class="ac-show" v-if="activityShow">
            <div>{{ activityInfo.shortTitle }}展示</div>
            <div class="ac-text">
              <div class="rich-text-preview" v-html="activityInfo.text" :rows="7" />
            </div>
          </div>
          <div class="qishi" v-if="noticeShow">
            <div class="rich-text-preview" v-html="activityInfo.text" :rows="7" />
          </div>
          <div class="ac-recommend" v-if="workShow">
            <div>本期优秀作品</div>
            <div class="recommend">
              <div class="top1" :style="{ backgroundImage: `url(` + top1ImgURL + `)` }" @click="onLookPost(top1Data.id)">
                <div class="top-title">{{ top1Data.title }}</div>
              </div>
              <div class="top2" :style="{ backgroundImage: `url(` + top2ImgURL + `)` }" @click="onLookPost(top2Data.id)">
                <div class="top-title">{{ top2Data.title }}</div>
              </div>
              <div class="top3" :style="{ backgroundImage: `url(` + top3ImgURL + `)` }" @click="onLookPost(top3Data.id)">
                <div class="top-title">{{ top3Data.title }}</div>
              </div>
              <div class="top4" :style="{ backgroundImage: `url(` + top4ImgURL + `)` }" @click="onLookPost(top4Data.id)">
                <div class="top-title">{{ top4Data.title }}</div>
              </div>
            </div>
          </div>
          <div class="activity-post" v-if="postShow">
            <div class="post-title">稿件浏览</div>
            <div class="post-con">
              <div class="post-list" v-for="p in activityPostList" :key="p.id" :style="{ backgroundImage: `url(` + p.imgURL + `)` }" @click="onLookPost(p.id)">
                <div class="title">{{ p.title }}</div>
              </div>
              <div class="page-container">
                <el-pagination
                  @current-change="handleCurrentChange"
                  :current-page="currentPage"
                  :page-size="20"
                  :hide-on-single-page="false"
                  layout="prev, pager, next, jumper"
                  :total="downTotal"
                ></el-pagination>
              </div>
            </div>
          </div>
          <div class="result" v-if="resultShow">
            <div class="result-title">获奖作品</div>
            <div class="result-con">
              <div class="result-list" v-for="(k, index) in activityInfo.results" :key="'result_' + index">
                <div class="result-title">{{ k.reward }}</div>
                <div class="result-post">
                  <div class="re-post" v-for="p in k.posts" :key="'repost' + p.id">
                    <div class="post-img" :style="{ backgroundImage: `url(` + p.reImgUrl + `)` }"></div>
                    <div class="post-text">{{ p.title }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="shareQR">
        <div class="share-text">分享到</div>
        <div class="share-img">
          <img :src="QRcodeImg" alt="" />
        </div>
      </div>
    </div>
    <div class="all mobile-present">
      <div class="activity-top" v-if="isShowImg">
        <img :src="iconImgURL" alt="" class="yan-img" />
      </div>
      <div class="activity-center">
        <div class="btn">
          <div class="btn-con" v-if="activityInfo.results">
            <div @click="onClikActivity(k.id)" v-for="k in showList" :class="{ active: currentRed == k.id }" :key="k.id">
              {{ k.name }}
            </div>
            <div v-if="activityInfo.results.length != 0" :class="{ active: currentRed == 5 }" class="btn-re" @click="onClikActivity(5)">获奖作品</div>
          </div>
        </div>
        <div class="activity-con">
          <div class="ac-show" v-if="activityShow">
            <div>{{ activityInfo.shortTitle }}展示</div>
            <div class="ac-text">
              <div class="rich-text-preview" v-html="activityInfo.text" :rows="7" />
            </div>
          </div>
          <div class="qishi" v-if="noticeShow">
            <div class="rich-text-preview" v-html="activityInfo.text" :rows="7" />
          </div>
          <div class="ac-recommend" v-if="workShow">
            <div>本期优秀作品</div>
            <div class="recommend">
              <div class="top1" :style="{ backgroundImage: `url(` + top1ImgURL + `)` }" @click="onLookPost(top1Data.id)">
                <div class="top-title">{{ top1Data.title }}</div>
              </div>
              <div class="top2" :style="{ backgroundImage: `url(` + top2ImgURL + `)` }" @click="onLookPost(top2Data.id)">
                <div class="top-title">{{ top2Data.title }}</div>
              </div>
              <div class="top3" :style="{ backgroundImage: `url(` + top3ImgURL + `)` }" @click="onLookPost(top3Data.id)">
                <div class="top-title">{{ top3Data.title }}</div>
              </div>
              <div class="top4" :style="{ backgroundImage: `url(` + top4ImgURL + `)` }" @click="onLookPost(top4Data.id)">
                <div class="top-title">{{ top4Data.title }}</div>
              </div>
            </div>
          </div>
          <div class="activity-post" v-if="postShow">
            <div class="post-title">稿件浏览</div>
            <div class="post-con">
              <div class="post-list" v-for="p in activityPostList" :key="p.id" :style="{ backgroundImage: `url(` + p.imgURL + `)` }" @click="onLookPost(p.id)">
                <div class="title">{{ p.title }}</div>
              </div>
              <div class="page-container">
                <el-pagination
                  @current-change="handleCurrentChange"
                  :current-page="currentPage"
                  :page-size="20"
                  :hide-on-single-page="false"
                  layout="prev, pager, next, jumper"
                  :total="downTotal"
                ></el-pagination>
              </div>
            </div>
          </div>
          <div class="result" v-if="resultShow">
            <div class="result-title">获奖作品</div>
            <div class="result-con">
              <div class="result-list" v-for="(k, index) in activityInfo.results" :key="'result_' + index">
                <div class="result-title">{{ k.reward }}</div>
                <div class="result-post">
                  <div class="re-post" v-for="p in k.posts" :key="'repost' + p.id">
                    <div class="post-img" :style="{ backgroundImage: `url(` + p.reImgUrl + `)` }"></div>
                    <div class="post-text">{{ p.title }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from '@/api/RD'
import Nav from '@/components/nav.vue'
import Bottom from '@/components/bottom.vue'
import QRCode from 'qrcode'
import wx from 'weixin-js-sdk'

export default {
  components: { Nav, Bottom },
  data: function () {
    return {
      activityInfo: {}, // 新闻图片的所有
      activityPostList: [],
      showList: [
        { id: 1, name: '活动展示' },
        { id: 2, name: '征稿启事' },
        { id: 3, name: '优秀作品' },
        { id: 4, name: '稿件浏览' },
      ], // 活动下的列表
      page: 1,
      currentPage: 1,
      downTotal: 1,
      iconImgURL: '', // 影赛图片
      top1ImgURL: '', // 推荐位图片
      top2ImgURL: '',
      top3ImgURL: '',
      top4ImgURL: '',
      top1Data: {},
      top2Data: {},
      top3Data: {},
      top4Data: {},
      isShowImg: false, // 是否显示标题图
      activityShow: false, // 活动展示
      workShow: false, // 优秀作品展示
      postShow: true, // 稿件展示
      noticeShow: false, // 征稿启事
      resultShow: false, // 获奖作品展示
      currentRed: 4, // 当前被点击的状态
      preType: false, // 文本的状态
      QRcodeImg: '', // 分享二维码的地址
    }
  },
  created() {
    this.reload()
  },
  mounted() {
    let thiz = this
    if (typeof WeixinJSBridge == 'undefined') {
      if (document.addEventListener) {
        document.addEventListener('WeixinJSBridgeReady', thiz.onBridgeReady(), false)
      } else if (document.attachEvent) {
        document.attachEvent('WeixinJSBridgeReady', thiz.onBridgeReady())
        document.attachEvent('onWeixinJSBridgeReady', thiz.onBridgeReady())
      }
    } else {
      thiz.onBridgeReady()
    }
  },
  methods: {
    onBridgeReady() {},
    async reload() {
      const thiz = this
      console.log('code', this.$route.query)
      if (this.$route.query.type == '报名参赛') {
        thiz.noticeShow = true
        thiz.activityShow = false
        thiz.currentRed = 2
      }
      var data = await RD.activityTopic().id(this.$route.query.code).one()
      thiz.shareSign(data)
      thiz.iconImgURL = undefined
      var imageTest = new Image()
      var imageURL = RD.activityTopic().id(this.$route.query.code).headPic_URL(1)
      imageTest.onload = () => {
        thiz.iconImgURL = imageURL
        thiz.isShowImg = true
      }
      imageTest.src = imageURL

      thiz.topRemcomend(data)
      var text = data.text

      if (text.indexOf('<') != -1) {
        thiz.preType = true
      } else {
        thiz.preType = false
      }

      data.results.forEach((k) => {
        k.posts.forEach((p) => {
          p.reImgUrl = RD.pic().id(p.frontPicId).mImage_URL()
        })
      })

      thiz.activityInfo = data
      var postData = await RD.activityTopic().id(this.$route.query.code).posts(thiz.page, 20)
      postData.list.forEach((k) => {
        if (k.frontPicId) {
          k.imgURL = RD.pic().id(k.frontPicId).mImage_URL()
        } else {
          return false
        }
      })
      thiz.activityPostList = postData.list
      thiz.currentPage = postData.pager.currentPage
      thiz.downTotal = postData.pager.total

      var shareUrl = window.location.href
      QRCode.toDataURL(shareUrl)
        .then((url) => {
          this.QRcodeImg = url
        })
        .catch((err) => {
          console.error(err)
        })
    },
    shareSign(data) {
      var link = location.href
      var descNow = data.text.substring(0, 99)
      var frontImgUrl = RD.activityTopic().id(this.$route.query.code).headPic_URL(1)
      wx.ready(function (res) {
        wx.updateAppMessageShareData({
          title: '安徽图片网' + '《' + data.title.trim() + '》',
          desc: descNow,
          link: link,
          imgUrl: frontImgUrl,
          trigger: function (res) {},
          success: function (res) {},
          cancel: function (res) {},
          fail: function (res) {},
        })
        wx.updateTimelineShareData({
          title: '安徽图片网' + '《' + data.title.trim() + '》',
          link: link,
          imgUrl: frontImgUrl,
          trigger: function (res) {},
          success: function (res) {},
          cancel: function (res) {},
          fail: function (res) {},
        })
      })
      RD.wx()
        .sign(link)
        .then((data) => {
          wx.config({
            debug: false,
            appId: data.appId,
            timestamp: data.timestamp,
            nonceStr: data.noncestr,
            signature: data.signature, // 签名
            jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'],
          })
          wx.error(function (res) {
            console.log(res)
          })
        })
    },
    topRemcomend(data) {
      const thiz = this
      if (data.top1 != undefined) {
        RD.post()
          .id(data.top1)
          .one()
          .then((info) => {
            thiz.top1Data = info
            thiz.top1ImgURL = RD.pic().id(info.frontPicId).mImage_URL()
          })
      }
      if (data.top2 != undefined) {
        RD.post()
          .id(data.top2)
          .one()
          .then((info) => {
            thiz.top2Data = info
            thiz.top2ImgURL = RD.pic().id(info.frontPicId).mImage_URL()
          })
      }

      if (data.top3 != undefined) {
        RD.post()
          .id(data.top3)
          .one()
          .then((info) => {
            thiz.top3Data = info
            thiz.top3ImgURL = RD.pic().id(info.frontPicId).mImage_URL()
          })
      }

      if (data.top4 != undefined) {
        RD.post()
          .id(data.top4)
          .one()
          .then((info) => {
            thiz.top4Data = info
            thiz.top4ImgURL = RD.pic().id(info.frontPicId).mImage_URL()
          })
      }
    },
    // 分页
    handleCurrentChange(val) {
      var thiz = this
      thiz.page = val
      thiz.currentPage = val
      console.log(1546, thiz.query)
      thiz.reload(thiz.page)
    },
    // 查看稿件
    onLookPost(postId) {
      if (postId == undefined) {
        this.$message('没有稿件ID')
        this.$router.push('/picture.html')
      } else {
        let routerUrl = this.$router.resolve({
          path: '/post.html',
          query: { id: postId },
        })
        window.open(routerUrl.href, '_blank')
      }
    },
    onClikActivity(type) {
      this.currentRed = type
      var thiz = this
      if (type == 1) {
        thiz.activityShow = true
        thiz.workShow = false
        thiz.postShow = false
        thiz.noticeShow = false
        thiz.resultShow = false
      } else if (type == 2) {
        thiz.activityShow = false
        thiz.noticeShow = true
        thiz.workShow = false
        thiz.postShow = false
        thiz.resultShow = false
      } else if (type == 3) {
        thiz.activityShow = false
        thiz.workShow = true
        thiz.postShow = false
        thiz.noticeShow = false
        thiz.resultShow = false
      } else if (type == 4) {
        thiz.activityShow = false
        thiz.workShow = false
        thiz.postShow = true
        thiz.noticeShow = false
        thiz.resultShow = false
      } else if (type == 5) {
        thiz.activityShow = false
        thiz.workShow = false
        thiz.postShow = false
        thiz.noticeShow = false
        thiz.resultShow = true
      }
    },
  },
}
</script>
<style lang="less">
pre {
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>

<style lang="less" scoped>
.whole {
  height: auto;
  border: 0.1rem solid #ccc;
  box-shadow: 0.5rem 1rem 0.5rem #d5d5d5;
  .all.desktop-present {
    width: 120rem;
    margin: 0 auto;
    .activity-top {
      img {
        width: 120rem;
      }
    }
    .activity-center {
      height: auto;
      margin-top: 2rem;
      .btn {
        height: 5rem;
        line-height: 5rem;
        background: rgba(0, 0, 0, 0.3);
        margin-bottom: 2rem;
        .btn-con {
          width: 65rem;
          display: flex;
          margin: 0 auto;
          font-size: 1.5rem;
          color: #fff;
          div {
            width: 12rem;
            margin-right: 1rem;
            cursor: pointer;
          }
          .btn-re {
            width: 12rem;
            flex-shrink: 0;
          }
        }
        .btn-con .active {
          border-bottom: 0.3rem solid red;
        }
      }
      .activity-con {
        display: flex;
        height: auto;
        .ac-show,
        .ac-recommend {
          width: 100%;
          overflow-y: auto;
          font-size: 2rem;
          font-weight: bold;
          margin-top: 1rem;
          .ac-text {
            .rich-text-preview {
              overflow-y: auto;
              ::v-deep * {
                text-align: left;
                line-height: 40px;
                font-size: 18px;
                word-spacing: 0.25em;
                margin: 0px;
              }
              ::v-deep pre {
                white-space: pre-wrap;
              }
              :v-deep img {
                width: 750px;
              }
            }
          }
          div {
            margin: 1rem;
          }
          .recommend {
            display: flex;
            flex-wrap: wrap;
            .top1,
            .top2,
            .top3,
            .top4 {
              width: 24rem;
              height: 15rem;
              border: 0.1rem solid #ccc;
              background: no-repeat 50% / cover;
              position: relative;
              .top-title {
                height: 2.5rem;
                width: 100%;
                position: absolute;
                margin: 0rem;
                padding: 0rem;
                bottom: 0rem;
                left: 50%;
                transform: translate(-50%);
                color: #fff;
                font-size: 1.3rem;
                background-color: rgba(0, 0, 0, 0.3);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
        .qishi {
          font-size: 1.3rem;
          text-align: left;
          text-indent: 2em;
          line-height: 3rem;
          .rich-text-preview {
            overflow-y: auto;
            ::v-deep * {
              text-align: left;
              line-height: 40px;
              font-size: 18px;
              word-spacing: 0.25em;
              margin: 0px;
            }
            ::v-deep pre {
              white-space: pre-wrap;
            }
            :v-deep img {
              width: 750px;
            }
          }
        }
        .activity-post {
          width: 120rem;
          margin: 0 zuto;
          .post-title {
            height: 4.5rem;
            font-size: 2rem;
            font-weight: bold;
            margin-top: 1rem;
          }
          .post-con {
            width: 140rem;
            overflow: hidden;
            height: auto;
            display: flex;
            flex-wrap: wrap;
            .post-list {
              width: 26.8rem;
              height: 15rem;
              border: 0.1rem solid #ccc;
              margin: 1.5rem;
              position: relative;
              background: no-repeat 50% / cover;
              cursor: pointer;
              .title {
                position: absolute;
                width: 100%;
                height: 3rem;
                line-height: 3rem;
                background-color: rgba(0, 0, 0, 0.2);
                bottom: 0rem;
                left: 50%;
                transform: translate(-50%);
                font-size: 1.3rem;
                color: #fff;
              }
            }
            .page-container {
              display: block;
              margin: 1.2rem;
              width: 80%;
              height: 3rem;
              line-height: 3rem;
              text-align: right;
              background-color: white;
            }
          }
        }
        .result {
          height: auto;
          width: 120rem;
          margin: 0 auto;
          .result-title {
            font-size: 2rem;
            font-weight: bold;
            height: 5rem;
            line-height: 5rem;
          }
          .result-con {
            font-size: 1.6rem;
            .result-list {
              height: auto;
              margin-bottom: 2rem;
              .result-title {
                font-size: 1.6rem;
                height: 30px;
              }
              .result-post {
                display: flex;
                height: auto;
                flex-wrap: wrap;
                .re-post {
                  width: 28.6rem;
                  height: 15.3rem;
                  position: relative;
                  .post-img {
                    height: 100%;
                    width: 100%;
                    background: no-repeat 50% / cover;
                    margin: 0rem;
                  }
                  .post-text {
                    height: 2.5rem;
                    width: 100%;
                    position: absolute;
                    margin: 0rem;
                    padding: 0rem;
                    bottom: 0rem;
                    left: 50%;
                    transform: translate(-50%);
                    color: #fff;
                    font-size: 1.3rem;
                    background-color: rgba(0, 0, 0, 0.3);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }
              }
            }
          }
        }
      }
    }
    .shareQR {
      position: fixed;
      top: 400px;
      right: -190px;
      display: flex;
      height: 180px;
      transition: all 200ms ease-in-out;
      transition-delay: 1s;
      border: 3px solid rgba(0, 0, 0, 0.5);
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      .share-text {
        width: 28px;
        height: 100%;
        background: #ccc;
        writing-mode: tb-rl;
        font-size: 1.5rem;
        text-align: center;
        color: #fff;
        text-shadow: 0px 0px 5px #000;
        padding-right: 10px;
      }
      .share-img {
        width: 180px;
        height: 180px;
        background-color: #fff;
        img {
          margin-top: 10px;
        }
      }
    }
    .shareQR:hover {
      right: 0px;
      transition-delay: 0s;
    }
  }
  .all.mobile-present {
    width: 750px;
    margin: 0 auto;
    .activity-top {
      img {
        width: 750px;
      }
    }
    .activity-center {
      height: auto;
      margin-top: 2rem;
      .btn {
        height: 5rem;
        line-height: 5rem;
        background: rgba(0, 0, 0, 0.3);
        margin-bottom: 2rem;
        .btn-con {
          width: 450px;
          display: flex;
          margin: 0 auto;
          font-size: 16px;
          color: #fff;
          div {
            width: 12rem;
            margin-right: 1rem;
            cursor: pointer;
          }
          .btn-re {
            width: 12rem;
            flex-shrink: 0;
          }
        }
        .btn-con .active {
          border-bottom: 0.3rem solid red;
        }
      }
      .activity-con {
        display: flex;
        height: auto;
        .ac-show,
        .ac-recommend {
          width: 100%;
          overflow-y: auto;
          font-size: 2rem;
          font-weight: bold;
          margin-top: 1rem;
          .ac-text {
            .rich-text-preview {
              overflow-y: auto;
              ::v-deep * {
                text-align: left;
                line-height: 40px;
                font-size: 18px;
                word-spacing: 0.25em;
                margin: 0px;
              }
              ::v-deep pre {
                white-space: pre-wrap;
              }
              :v-deep img {
                width: 750px;
              }
            }
          }
          div {
            margin: 1rem;
          }
          .recommend {
            display: flex;
            flex-wrap: wrap;
            .top1,
            .top2,
            .top3,
            .top4 {
              width: 24rem;
              height: 15rem;
              border: 0.1rem solid #ccc;
              background: no-repeat 50% / cover;
              position: relative;
              .top-title {
                height: 2.5rem;
                width: 100%;
                position: absolute;
                margin: 0rem;
                padding: 0rem;
                bottom: 0rem;
                left: 50%;
                transform: translate(-50%);
                color: #fff;
                font-size: 1.3rem;
                background-color: rgba(0, 0, 0, 0.3);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
        .qishi {
          font-size: 1.3rem;
          text-align: left;
          text-indent: 2em;
          line-height: 3rem;
          .rich-text-preview {
            overflow-y: auto;
            ::v-deep * {
              text-align: left;
              line-height: 40px;
              font-size: 18px;
              word-spacing: 0.25em;
              margin: 0px;
            }
            ::v-deep pre {
              white-space: pre-wrap;
            }
            :v-deep img {
              width: 750px;
            }
          }
        }
        .activity-post {
          width: 750px;
          margin: 0 zuto;
          .post-title {
            height: 4.5rem;
            font-size: 2rem;
            font-weight: bold;
            margin-top: 1rem;
          }
          .post-con {
            width: 750px;
            overflow: hidden;
            height: auto;
            display: flex;
            flex-wrap: wrap;
            .post-list {
              width: 26.8rem;
              height: 15rem;
              border: 0.1rem solid #ccc;
              margin: 1.5rem;
              position: relative;
              background: no-repeat 50% / cover;
              .title {
                position: absolute;
                width: 100%;
                height: 3rem;
                line-height: 3rem;
                background-color: rgba(0, 0, 0, 0.2);
                bottom: 0rem;
                left: 50%;
                transform: translate(-50%);
                font-size: 1.3rem;
                color: #fff;
              }
            }
            .page-container {
              display: block;
              margin: 1.2rem;
              width: 80%;
              height: 3rem;
              line-height: 3rem;
              text-align: right;
              background-color: white;
            }
          }
        }
        .result {
          height: auto;
          width: 750px;
          margin: 0 auto;
          .result-title {
            font-size: 20px;
            font-weight: bold;
            height: 5rem;
            line-height: 5rem;
          }
          .result-con {
            font-size: 1.6rem;
            .result-list {
              height: auto;
              margin-bottom: 2rem;
              .result-title {
                font-size: 16px;
                height: 30px;
              }
              .result-post {
                display: flex;
                height: auto;
                flex-wrap: wrap;
                .re-post {
                  width: 28.6rem;
                  height: 15.3rem;
                  position: relative;
                  .post-img {
                    height: 100%;
                    width: 100%;
                    background: no-repeat 50% / cover;
                    margin: 0rem;
                  }
                  .post-text {
                    height: 2.5rem;
                    width: 100%;
                    position: absolute;
                    margin: 0rem;
                    padding: 0rem;
                    bottom: 0rem;
                    left: 50%;
                    transform: translate(-50%);
                    color: #fff;
                    font-size: 1.3rem;
                    background-color: rgba(0, 0, 0, 0.3);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
